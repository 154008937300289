import ApiResponse from "@/api/infrastructure/ApiResponse";
import ApiResponseWithBody from "@/api/infrastructure/ApiResponseWithBody";
import ApiError from "@/api/infrastructure/ApiError";

export type QueryParams = Record<string, string>;
export type Body = any;

const tokenStorageKey = "token";

export default class ApiClient {
  public static _useAuth = false;
  private static _baseUrl: string | null = null;
  private static _token: string | null = null;

  static set baseUrl(url: string | null) {
    if (url === null || url === "") {
      this._baseUrl = null;

      return;
    }

    if (!url.endsWith("/")) {
      url += "/";
    }

    this._baseUrl = url;
  }

  static get baseUrl(): string | null {
    return this._baseUrl;
  }

  static set token(token: string | null) {
    this._token = token;
    this._useAuth = token !== null;

    if (token !== null) {
      localStorage.setItem(tokenStorageKey, token);
    } else {
      localStorage.removeItem(tokenStorageKey);
    }
  }

  static get token(): string | null {
    if (this._token === null) {
      this._token = localStorage.getItem(tokenStorageKey);
    }

    return this._token;
  }

  private static async buildHeaders(
    hasContent: boolean = false
  ): Promise<HeadersInit> {
    const headers = new Headers();

    if (hasContent) {
      headers.set("Content-Type", "application/json");
    }

    if (this.token !== null) {
      headers.set("Authorization", "Bearer " + this.token);
    }

    return headers;
  }

  public static getFullUrl(
    url: string,
    query: QueryParams | null = null
  ): string {
    let fqu: URL;
    if (url.indexOf("https://") === 0) {
      fqu = new URL(url); // url is already absolute
    } else {
      if (url.startsWith("/")) {
        url = url.substring(1);
      }

      if (this.baseUrl !== null) {
        fqu = new URL(this.baseUrl + url); // prepend base url if available
      } else {
        fqu = new URL(url); // let the browser figure it out
      }
    }

    if (query !== null) {
      for (const key in query) {
        fqu.searchParams.set(key, query[key]);
      }
    }

    return fqu.href;
  }

  static async invoke(
    method: string,
    url: string,
    body: Body | null = null,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    const fullUrl = this.getFullUrl(url, query);

    console.debug(
      `[ApiClient] [${method}] ${fullUrl.substring(baseUrl?.length ?? 0)}`
    );

    const requestInit: RequestInit = {
      method: method,
      headers: await this.buildHeaders(true),
      credentials: this._useAuth ? "include" : "omit",
    };

    if (body !== null) {
      requestInit.body = JSON.stringify(body);
    }

    const requestStart = Date.now();
    let response: Response;
    try {
      response = await fetch(fullUrl, requestInit);
    } catch (e) {
      throw new ApiError(undefined, e);
    }

    const requestEnd = Date.now();

    const apiResponse = new ApiResponse(response);
    if (throwOnNonSuccess && !response.ok) {
      throw new ApiError(apiResponse);
    }

    console.debug(
      `[ApiClient] ${response.status}: [${method}] ${fullUrl.substring(
        baseUrl?.length ?? 0
      )} (${requestEnd - requestStart}ms)`
    );

    return apiResponse;
  }

  static async invokeGetBody<T = unknown>(
    method: string,
    url: string,
    body: Body | null = null,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponseWithBody<T>> {
    const apiResponse = await this.invoke(
      method,
      url,
      body,
      query,
      throwOnNonSuccess
    );

    try {
      const responseBody = (await apiResponse.original.json()) as T;

      return new ApiResponseWithBody(apiResponse.original, responseBody);
    } catch (e) {
      throw new ApiError(apiResponse, e);
    }
  }

  static async get(
    url: string,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    return await this.invoke("GET", url, null, query, throwOnNonSuccess);
  }

  static async head(
    url: string,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    return await this.invoke("HEAD", url, null, query, throwOnNonSuccess);
  }

  static async delete(
    url: string,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    return await this.invoke("DELETE", url, null, query, throwOnNonSuccess);
  }

  static async post<TRequest extends Body | null = null>(
    url: string,
    body: TRequest,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    return await this.invoke("POST", url, body, query, throwOnNonSuccess);
  }

  static async patch<TRequest extends Body | null = null>(
    url: string,
    body: TRequest,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponse> {
    return await this.invoke("PATCH", url, body, query, throwOnNonSuccess);
  }

  static async getJson<T>(
    url: string,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponseWithBody<T>> {
    return await this.invokeGetBody<T>(
      "GET",
      url,
      null,
      query,
      throwOnNonSuccess
    );
  }

  static async postJson<TRequest extends Body | null, T>(
    url: string,
    body: TRequest,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ): Promise<ApiResponseWithBody<T>> {
    return await this.invokeGetBody<T>(
      "POST",
      url,
      body,
      query,
      throwOnNonSuccess
    );
  }

  static async postBlob<TRequest extends Body | null>(
    url: string,
    body: TRequest,
    query: QueryParams | null = null,
    throwOnNonSuccess: boolean = true
  ) {
    const response = await this.invoke(
      "POST",
      url,
      body,
      query,
      throwOnNonSuccess
    );

    const blob = await response.original.blob();

    const contentDisposition =
      (response.original.headers.get("Content-Disposition") as string|null) ??
      "filename=unknown";

    const contentDispositionParts = contentDisposition
      .split(";")
      .map((x) => x.trim());

    const fileName =
      (contentDispositionParts
        .find((x) => x.startsWith("filename="))
        ?.substring("filename=".length) ?? "unknown")
        .replaceAll("\"", "");

    return {
      blob,
      fileName,
    };
  }
}

const baseUrl = import.meta.env.VITE_API_BASE_URL;
if (typeof baseUrl !== "undefined") {
  ApiClient.baseUrl = baseUrl;
} else {
  alert("VITE_API_BASE_URL not set in .env");
}
